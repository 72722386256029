import {ITable, IVariable, IYearCatalogs} from "../../interfaces";
import {IDisposable} from "monaco-editor";
import {loader} from "@monaco-editor/react";
import Medcodelogic from "./medcodelogic";
import MedcodelogicActions from "./medcodelogicActions";
import {ProceduresService} from "../../services/ProceduresService";
import {DiagnosesService} from "../../services/DiagnosesService";
import {DrgsService} from "../../services/DrgsService";
import {SupplementsService} from "../../services/SupplementsService";
import {MedcodelogicService} from "../../services/MedcodelogicService";

const inTableMatcher = text => text.match(/in table[s]?\s*\([^)]*$/i)
const inListMatcher = text => text.match(/in list[s]?\s*\([^)]*$/i)

/* setup the custom language medcodelogic in monaco. */
export function setupMedcodelogicInMonaco(variables: IVariable[], locale: string, tables: ITable[],
                                          tableScope: string, catalogs: IYearCatalogs): IDisposable[] {
                                            
    const disposers: IDisposable[] = []
    loader.init().then((monaco) => {
        setupErrors(monaco, disposers, tableScope);
        // Register a new language
        if (!monaco.languages.getLanguages().some(lang => lang.id === 'medcodelogic')) {
            monaco.languages.register({id: 'medcodelogic'});
            monaco.languages.setLanguageConfiguration("medcodelogic",
            {wordPattern: /([a-zA-Z0-9_*.]+)/g})
            setupTheme(monaco);
        }
        
        setupTokenizer(monaco, disposers, variables, tables);
       
        const variableTypes = {}
        variables.forEach(variable => {variableTypes[variable.field_name] = variable.table_type})
        const tableTypeToService = {
            icd: DiagnosesService,
            chop: ProceduresService ,
            drg: DrgsService,
            supplement: SupplementsService
            /** TODO all services. */
        }
        const enumVariables = {}
        const subAttributes: {[key: string]: IVariable[]} = {}
        variables.forEach(v => {
            if(v.is_enum)
                enumVariables[v.field_name] = v
            if(v.is_sub_attribute){
                if(subAttributes[v.code_entry_model] === undefined)
                    subAttributes[v.code_entry_model] = []
                subAttributes[v.code_entry_model].push(v)
            }
        })
        /** remove sub attributes. */
        setupCompletion(monaco, disposers, tables, variables.filter(v => !v.is_sub_attribute), locale,
            variableTypes, tableTypeToService, enumVariables, subAttributes, catalogs);
        setupHovering(monaco, disposers, tables, variables, locale, variableTypes, enumVariables, catalogs)
    });
    return disposers
}

function setupTokenizer( monaco, disposers: IDisposable[],variables: IVariable[], tables: ITable[]) {
    // Register a tokens provider for the language
    disposers.push(monaco.languages.setMonarchTokensProvider('medcodelogic', {
        keywords: ['lookup', 'where', 'and', 'or'],
        variables: variables.map(v => v.field_name),
        tables: tables.map(t => t.name),
        functions: ['sides', 'date', 'dates', 'max', 'min', 'in', 'table', 'list', 'not', 'empty'],
        operators: [
            '=', '>', '<', '<=', '>=', '!=', '+', '-', '*', '/'
        ],
        symbols: /[=><!~?:&|+\-*/^%]+/,
        tokenizer: {

            root: [
                // identifiers and keywords
                [/[_a-zA-Z0-9*.]+/, {
                    cases: {
                        '@keywords': 'keyword',
                        '@variables': 'variable',
                        "@tables": "tables",
                        '@functions': 'function',
                        '@default': 'identifier'
                    }
                }],

                // whitespace
                {include: '@whitespace'},

                // delimiters and operators
                [/[()]/, '@brackets'],
                [/@symbols/, {
                    cases: {
                        '@operators': 'operator',
                        '@default': ''
                    }
                }],

                // numbers
                [/\d*\.\d+([eE][-+]?\d+)?/, 'number.float'],
                [/\d+/, 'number'],

                // strings
                [/"([^"\\]|\\.)*$/, 'string.invalid'],  // non-teminated string
                [/"/, {token: 'string.quote', bracket: '@open', next: '@string'}],

                // characters
                [/'[^\\']'/, 'string'],
                [/'/, 'string.invalid']
            ],
            string: [
                [/[^\\"]+/, 'string'],
                [/"/, {token: 'string.quote', bracket: '@close', next: '@pop'}]
            ],
            whitespace: [
                [/[ \t\r\n]+/, 'white']
            ],
        }
    }));
}

function setupErrors(monaco, disposers: IDisposable[], tableScope) {
    /** parse line number and offset within line from input text and global offset. */
    const parseOffsetsFromInputText = (logicSrc, offset) => {
        const lines = logicSrc.split(/\n/g)
        let lineNo = 0
        let position = 0
        let startColumn = offset

        while (position <= offset) {
            position += lines[lineNo].length + 1;
            lineNo += 1;
            if (lineNo > 1) {
                startColumn -= (lines[lineNo - 2].length + 1)
            }
        }
        return [lineNo, startColumn]
    }
    /** parser integration: show syntax errors. */
    const validate = (model) => {
        const logicSrc = model.getValue();
        try {
            if (logicSrc.trim() === '') {
                monaco.editor.removeAllMarkers('medcodelogic')
                return
            }
            Medcodelogic.parse(logicSrc, { actions: MedcodelogicActions })
            monaco.editor.removeAllMarkers('medcodelogic')
            /** semantic code analysis. done in backend. */
            return MedcodelogicService.checkCode(logicSrc, tableScope).then(response => {
                const errors = response.data ? (response.data['errors'] || []) : [];
                const markers = (errors || []).map(error => {
                    // set start / end positions
                    const [startLine, startColumn] = parseOffsetsFromInputText(logicSrc, error.start)
                    const [endLine, endColumn] = parseOffsetsFromInputText(logicSrc, error.end)

                    return {
                        severity: monaco.MarkerSeverity.Error,
                        startLineNumber: startLine,
                        startColumn: startColumn,
                        endLineNumber: endLine,
                        endColumn: endColumn + 1,
                        message: error.msg.toString()
                    }
                });
                monaco.editor.setModelMarkers(model, 'medcodelogic', markers);
            })
        } catch (error) {
            const offset = Medcodelogic.Parser.lastError.offset
            const [lineNo, startColumn] = parseOffsetsFromInputText(logicSrc, offset)

            const markers = [{
                severity: monaco.MarkerSeverity.Error,
                startLineNumber: lineNo,
                startColumn: startColumn,
                endLineNumber: lineNo,
                endColumn: model.getLineMaxColumn(lineNo),
                message: error.toString()
            }];
            monaco.editor.setModelMarkers(model, 'medcodelogic', markers);
        }
    }

    const addValidationToModel = (model) => {
        let handle = null;
        disposers.push(model.onDidChangeContent(() => {
            // debounce
            clearTimeout(handle);
            handle = setTimeout(() => validate(model), 500);
        }));
        /* validate on load. */
        validate(model);
    }

    /* this handles already loaded models. */
    monaco.editor.getModels().forEach(model => {
       addValidationToModel(model);
    });
    /* and this handles new models. */
    monaco.editor.onDidCreateModel(model => {
        addValidationToModel(model);
    });
}

function setupTheme(monaco) {
    // Define a new theme that contains only rules that match this language
    monaco.editor.defineTheme('medcodelogicTheme', {
        base: 'vs',
        inherit: true,
        rules: [
            {token: 'tables', foreground: '#0255a2'},
            {token: 'string', foreground: '#ff872f'},
            {token: 'number', foreground: '#ff872f'},
            {token: 'operators', fontStyle: 'font-weight: bold;'},
            {token: 'variable', foreground: '#117700'},
            {token: 'keyword', foreground: '#01407d'},
            {token: 'function', foreground: '#aa1111'}
        ],
        colors: {
            'editor.foreground': '#000000',
        },
    });
}

function setupCompletion(monaco, disposers: IDisposable[], tables: ITable[], variables: IVariable[], locale: string,
                         variableTypes, tableTypeToService, enumVariables, subAttributes, catalogs: IYearCatalogs) {
    /** variable autocompletion. */
    const mapVariableToCompletion = variable => {
        const label = variable.field_name + " - " + variable['name_' + locale];
        const detail = variable['description_' + locale];
        return {
            label: label,
            kind: monaco.languages.CompletionItemKind.Variable,
            insertText: variable.field_name,
            detail: detail,
            filterText: label + " " + detail,
        }
    }
    let autoCompletionList = variables.map(variable => mapVariableToCompletion(variable))
    /** sub attributes in where clauses. */
    const subAttributesCompletions = Object.fromEntries(Object.entries(subAttributes)
        .map((e: [string, IVariable[]]) => [e[0], e[1].map(v => mapVariableToCompletion(v))]));
    /** complex variables that can be used in front of a where clause. */
    const objectVariables = variables.filter(v => v.code_entry_model && !v.is_sub_attribute)
    /** table autocompletion. */
    const tableAutoCompletionList = tables.map(table => {
        return {
            label: table.name + " - " + table['text_' + locale] + " (" + table.table_type + ")",
            kind: monaco.languages.CompletionItemKind.Constant,
            insertText: table.name,
        }
    });
    /** function autocompletion. */
    autoCompletionList = autoCompletionList.concat(
        ['sides', 'date', 'dates', 'max', 'min', 'in table', 'in list', 'not', 'empty'].map(f => {
            return {
                label: f,
                kind: monaco.languages.CompletionItemKind.Function,
                insertText: f + '($0)',
                insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet,
                detail: '',
                filterText: f
            }
        }));
    autoCompletionList = autoCompletionList.concat(['lookup', 'where', 'and', 'or'].map(f => {
        return {
            label: f,
            kind: monaco.languages.CompletionItemKind.Function,
            insertText: f,
            detail: '',
            filterText: f
        }
    }));

    disposers.push(monaco.languages.registerCompletionItemProvider('medcodelogic', {
        triggerCharacters: [".", "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "\"", "'"],
        provideCompletionItems: function (model, position) {
            // The maximum options being suggested.
            const maxOptions = 6
            let allSuggestions = autoCompletionList

            // Get the current word and its range
            const word = model.getWordUntilPosition(position);
            const range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn
            };

            // are we in a special context (table or code lists) ?
            const textUntilPosition = model.getValueInRange({
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });

            if (inTableMatcher(textUntilPosition)) {
                /** return tables only. **/
                allSuggestions = tableAutoCompletionList
            }

            if (inListMatcher(textUntilPosition)) {
                const search = word.word
                /** skip search if too short. */
                if(search.length < 3)
                    return {suggestions: []};
                /** maybe add try/catch. */
                /** detect catalog by scanning variables before "in list". This only works for simple constructs. */
                const variable = textUntilPosition.replace('not in list', 'in list').split("in list").slice(-2)[0].trim().split(/\s/).slice(-1)[0]
                if(variableTypes[variable] && "string" != variableTypes[variable]){
                    /** code search is initiated using medcodesearch. */
                    const codeType = variableTypes[variable];
                    return tableTypeToService[codeType].get(search, catalogs[codeType], 0).then(response => {
                        return {suggestions: response.data.map(searchResult => {
                            const code = searchResult.code + (searchResult.terminal === false ? '*' : '')
                            return {
                                label: code + " - " + searchResult.text,
                                kind: monaco.languages.CompletionItemKind.Constant,
                                insertText: code,
                                range: range
                            }}), incomplete: true};
                    })
                }
                return {suggestions: []};
            }

            /** where clauses. */
            if (textUntilPosition.match(
                /where\s*(\([^)]*|.*)$/i
            )) {
                objectVariables.forEach(objectV => {
                    if(textUntilPosition.includes(objectV.field_name)){
                        allSuggestions = allSuggestions.concat(subAttributesCompletions[objectV.code_entry_model])
                    }
                })
            }

            /** enum variables. */
            const enumVariable = textUntilPosition.match(/([_a-zA-Z0-9*.]+)\s*(=|!=)\s*(["'])[^"^']*$/)
            if (enumVariable && enumVariables[enumVariable[1]]) {
                const variable = enumVariables[enumVariable[1]]
                const endString = enumVariable[3]
                return {
                    suggestions: variable.values.map((s, i) => {
                        return {
                            label: s + " - " + variable['values_' + locale][i],
                            kind: monaco.languages.CompletionItemKind.Constant,
                            insertText: s + endString
                        }
                    }),
                    incomplete: false,
                    replaceRange: range
                };
            }

            /* Filter the suggestions based on the current word. 
                Note that monaco will do a search itself on label or if present on filterText.
                However by prefiltering we can force some behaviour. */
            let filteredSuggestions = allSuggestions.filter(suggestion =>
                suggestion.label.startsWith(word.word)).slice(0, maxOptions);
            if (filteredSuggestions.length == 0) {
                filteredSuggestions = allSuggestions.filter(suggestion =>
                    (suggestion.label + ' - ' + (suggestion.detail || '')).toLowerCase().includes(word.word.toLowerCase())
                ).slice(0, maxOptions);
            }

            return {
                suggestions: filteredSuggestions.map(s => {
                    return {...s, range: range}
                }),
                incomplete: true,
                replaceRange: range
            };
        }
    }));
}

function setupHovering(monaco, disposers: IDisposable[], tables: ITable[], variables: IVariable[], locale: string,
                       variableTypes, enumVariables, catalogs: IYearCatalogs) {
    const tablesByName = {}
    tables.forEach(table => {tablesByName[table.name] = table['text_' + locale] + " (" + table.table_type + ")"})
    const variablesByName = {}
    variables.forEach(variable => {
        let variableTypeDesc = variable.variable_type == 'code' ? variable.table_type : variable.variable_type
        if(variable.isarray){
            variableTypeDesc = "list of " + variableTypeDesc + "s"
        }
        variablesByName[variable.field_name] = variable['name_' + locale]+ " (" + variableTypeDesc + ")"
        if(variable['description_' + locale]){
            /* add description if available. add line break with "\n\r" */
            variablesByName[variable.field_name] += "\n\r" + variable['description_' + locale]
        }
    })

    disposers.push(monaco.languages.registerHoverProvider('medcodelogic', {
        provideHover: function(model, position) {
            // Get the current word and its range
            const word = model.getWordAtPosition(position);
            if(!word){
                return
            }
            const range = {
                startLineNumber: position.lineNumber,
                endLineNumber: position.lineNumber,
                startColumn: word.startColumn,
                endColumn: word.endColumn
            };
            let titles = variablesByName
            // are we in a special context (table or code lists) ?
            const textUntilPosition = model.getValueInRange({
                startLineNumber: 1,
                startColumn: 1,
                endLineNumber: position.lineNumber,
                endColumn: position.column,
            });

            if (inTableMatcher(textUntilPosition)) {
                /** return tables only. **/
                titles = tablesByName
            }
            if (inListMatcher(textUntilPosition))  {
                const code = word.word
                /** maybe add try/catch. */
                /** detect catalog by scanning variables before "in list". This only works for simple constructs. */
                const variable = textUntilPosition.split("in list").slice(-2)[0].trim().split(/\s/).slice(-1)[0]
                if(variableTypes[variable] && variableTypes[variable] != 'string'){
                    /** code name lookup. */
                    const hasWildCard = code.includes('*')
                    return MedcodelogicService.getCodeText(variableTypes[variable], code, locale, catalogs.year).then(response => {
                        const text = response.data.text;
                        return {
                            contents: [{value: text || (hasWildCard ? 'wildcard code / non terminal' : 'invalid code')}],
                            range: range
                        };
                    })
                }
                return
            }

            /** enum variables. */
            const enumVariable = textUntilPosition.match(/([_a-zA-Z0-9*.]+)\s*(=|!=)\s*(["'])[^"^']*$/)
            if (enumVariable && enumVariables[enumVariable[1]]) {
                const variable = enumVariables[enumVariable[1]]
                let text = "illegal value"
                variable.values.forEach((value, i) => {
                    if(value == word.word)
                        text = variable['values_' + locale][i]
                })
                return {
                    contents: [{value: text }],
                    range: range
                }
            }

            const title = titles[word.word]
            if(!title){
                return
            }

            return {
                range: range,
                contents: [{value: title}]
            }
        }
    }));
}
