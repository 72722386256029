/**
 * This file was generated from medcodelogic.peg
 * See https://canopy.jcoglan.com/ for documentation
 */

(function () {
  'use strict';

  function TreeNode (text, offset, elements) {
    this.text = text;
    this.offset = offset;
    this.elements = elements;
  }

  TreeNode.prototype.forEach = function (block, context) {
    for (var el = this.elements, i = 0, n = el.length; i < n; i++) {
      block.call(context, el[i], i, el);
    }
  };

  if (typeof Symbol !== 'undefined' && Symbol.iterator) {
    TreeNode.prototype[Symbol.iterator] = function () {
      return this.elements[Symbol.iterator]();
    };
  }

  var TreeNode1 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['and'] = elements[0];
  };
  inherit(TreeNode1, TreeNode);

  var TreeNode2 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expressions_or'] = elements[0];
    this['and'] = elements[0];
  };
  inherit(TreeNode2, TreeNode);

  var TreeNode3 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['comparison'] = elements[0];
  };
  inherit(TreeNode3, TreeNode);

  var TreeNode4 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expressions'] = elements[0];
    this['comparison'] = elements[0];
  };
  inherit(TreeNode4, TreeNode);

  var TreeNode5 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['sum'] = elements[0];
  };
  inherit(TreeNode5, TreeNode);

  var TreeNode6 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['operators'] = elements[0];
    this['operator'] = elements[0];
    this['expressions'] = elements[1];
    this['sum'] = elements[1];
  };
  inherit(TreeNode6, TreeNode);

  var TreeNode7 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['product'] = elements[0];
  };
  inherit(TreeNode7, TreeNode);

  var TreeNode8 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['operators'] = elements[0];
    this['expressions'] = elements[1];
    this['product'] = elements[1];
  };
  inherit(TreeNode8, TreeNode);

  var TreeNode9 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['where'] = elements[0];
  };
  inherit(TreeNode9, TreeNode);

  var TreeNode10 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['operators'] = elements[0];
    this['expressions'] = elements[1];
    this['where'] = elements[1];
  };
  inherit(TreeNode10, TreeNode);

  var TreeNode11 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['in_table'] = elements[0];
  };
  inherit(TreeNode11, TreeNode);

  var TreeNode12 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expression'] = elements[0];
  };
  inherit(TreeNode12, TreeNode);

  var TreeNode13 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['in_list'] = elements[0];
  };
  inherit(TreeNode13, TreeNode);

  var TreeNode14 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['in_table_op'] = elements[0];
    this['table_list'] = elements[1];
  };
  inherit(TreeNode14, TreeNode);

  var TreeNode15 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['first'] = elements[0];
    this['simple_expression'] = elements[0];
  };
  inherit(TreeNode15, TreeNode);

  var TreeNode16 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['in_list_op'] = elements[0];
    this['code_list'] = elements[1];
  };
  inherit(TreeNode16, TreeNode);

  var TreeNode17 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['lparen'] = elements[0];
    this['expression'] = elements[1];
  };
  inherit(TreeNode17, TreeNode);

  var TreeNode18 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['identifier'] = elements[0];
  };
  inherit(TreeNode18, TreeNode);

  var TreeNode19 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['variable'] = elements[0];
    this['table'] = elements[1];
  };
  inherit(TreeNode19, TreeNode);

  var TreeNode20 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['variable'] = elements[0];
  };
  inherit(TreeNode20, TreeNode);

  var TreeNode21 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['identifier'] = elements[0];
    this['list'] = elements[1];
  };
  inherit(TreeNode21, TreeNode);

  var TreeNode22 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expression'] = elements[0];
  };
  inherit(TreeNode22, TreeNode);

  var TreeNode23 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expression'] = elements[0];
  };
  inherit(TreeNode23, TreeNode);

  var TreeNode24 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['table'] = elements[0];
  };
  inherit(TreeNode24, TreeNode);

  var TreeNode25 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['table'] = elements[0];
  };
  inherit(TreeNode25, TreeNode);

  var TreeNode26 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['type'] = elements[0];
    this['identifier'] = elements[1];
    this['parameter_list'] = elements[2];
  };
  inherit(TreeNode26, TreeNode);

  var TreeNode27 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['type'] = elements[0];
    this['identifier'] = elements[1];
  };
  inherit(TreeNode27, TreeNode);

  var TreeNode28 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['type'] = elements[0];
    this['identifier'] = elements[1];
  };
  inherit(TreeNode28, TreeNode);

  var TreeNode29 = function (text, offset, elements) {
    TreeNode.apply(this, arguments);
    this['expression'] = elements[0];
  };
  inherit(TreeNode29, TreeNode);

  var FAILURE = {};

  var Grammar = {
    _read_expression () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._expression = this._cache._expression || {};
      var cached = this._cache._expression[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      address0 = this._read_or();
      if (address0 === FAILURE) {
        this._offset = index1;
        var chunk0 = null, max0 = this._offset + 4;
        if (max0 <= this._inputSize) {
          chunk0 = this._input.substring(this._offset, max0);
        }
        if (chunk0 === 'true') {
          address0 = this._actions.makeExpression(this._input, this._offset, this._offset + 4, []);
          this._offset = this._offset + 4;
        } else {
          address0 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push(['Medcodelogic::expression', '"true"']);
          }
        }
        if (address0 === FAILURE) {
          this._offset = index1;
        }
      }
      this._cache._expression[index0] = [address0, this._offset];
      return address0;
    },

    _read_or () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._or = this._cache._or || {};
      var cached = this._cache._or[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_and();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            var index4 = this._offset, elements3 = new Array(1);
            var address6 = FAILURE;
            var chunk0 = null, max0 = this._offset + 2;
            if (max0 <= this._inputSize) {
              chunk0 = this._input.substring(this._offset, max0);
            }
            if (chunk0 !== null && chunk0.toLowerCase() === 'or'.toLowerCase()) {
              address6 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
              this._offset = this._offset + 2;
            } else {
              address6 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::or', '`or`']);
              }
            }
            if (address6 !== FAILURE) {
              var address7 = FAILURE;
              var index5 = this._offset, elements4 = [], address8 = null;
              while (true) {
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements4.push(address8);
                } else {
                  break;
                }
              }
              if (elements4.length >= 0) {
                address7 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                this._offset = this._offset;
              } else {
                address7 = FAILURE;
              }
              if (address7 !== FAILURE) {
                var address9 = FAILURE;
                address9 = this._read_and();
                if (address9 !== FAILURE) {
                  elements3[0] = address9;
                } else {
                  elements3 = null;
                  this._offset = index4;
                }
              } else {
                elements3 = null;
                this._offset = index4;
              }
            } else {
              elements3 = null;
              this._offset = index4;
            }
            if (elements3 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode2(this._input.substring(index4, this._offset), index4, elements3);
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeOr(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._or[index0] = [address0, this._offset];
      return address0;
    },

    _read_and () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._and = this._cache._and || {};
      var cached = this._cache._and[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_comparison();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            var index4 = this._offset, elements3 = new Array(1);
            var address6 = FAILURE;
            var chunk0 = null, max0 = this._offset + 3;
            if (max0 <= this._inputSize) {
              chunk0 = this._input.substring(this._offset, max0);
            }
            if (chunk0 !== null && chunk0.toLowerCase() === 'and'.toLowerCase()) {
              address6 = new TreeNode(this._input.substring(this._offset, this._offset + 3), this._offset, []);
              this._offset = this._offset + 3;
            } else {
              address6 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::and', '`and`']);
              }
            }
            if (address6 !== FAILURE) {
              var address7 = FAILURE;
              var index5 = this._offset, elements4 = [], address8 = null;
              while (true) {
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements4.push(address8);
                } else {
                  break;
                }
              }
              if (elements4.length >= 0) {
                address7 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                this._offset = this._offset;
              } else {
                address7 = FAILURE;
              }
              if (address7 !== FAILURE) {
                var address9 = FAILURE;
                address9 = this._read_comparison();
                if (address9 !== FAILURE) {
                  elements3[0] = address9;
                } else {
                  elements3 = null;
                  this._offset = index4;
                }
              } else {
                elements3 = null;
                this._offset = index4;
              }
            } else {
              elements3 = null;
              this._offset = index4;
            }
            if (elements3 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode4(this._input.substring(index4, this._offset), index4, elements3);
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeAnd(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._and[index0] = [address0, this._offset];
      return address0;
    },

    _read_comparison () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._comparison = this._cache._comparison || {};
      var cached = this._cache._comparison[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_sum();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset;
          var index4 = this._offset, elements2 = new Array(2);
          var address5 = FAILURE;
          address5 = this._read_operator();
          if (address5 !== FAILURE) {
            elements2[0] = address5;
            var address6 = FAILURE;
            var index5 = this._offset, elements3 = [], address7 = null;
            while (true) {
              address7 = this._read_space();
              if (address7 !== FAILURE) {
                elements3.push(address7);
              } else {
                break;
              }
            }
            if (elements3.length >= 0) {
              address6 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
              this._offset = this._offset;
            } else {
              address6 = FAILURE;
            }
            if (address6 !== FAILURE) {
              var address8 = FAILURE;
              address8 = this._read_sum();
              if (address8 !== FAILURE) {
                elements2[1] = address8;
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address4 = FAILURE;
          } else {
            address4 = new TreeNode6(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address4 === FAILURE) {
            address4 = new TreeNode(this._input.substring(index3, index3), index3, []);
            this._offset = index3;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeComparison(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._comparison[index0] = [address0, this._offset];
      return address0;
    },

    _read_sum () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._sum = this._cache._sum || {};
      var cached = this._cache._sum[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_product();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            var index4 = this._offset, elements3 = new Array(2);
            var address6 = FAILURE;
            var chunk0 = null, max0 = this._offset + 1;
            if (max0 <= this._inputSize) {
              chunk0 = this._input.substring(this._offset, max0);
            }
            if (chunk0 !== null && /^[+-]/.test(chunk0)) {
              address6 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address6 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::sum', '[+-]']);
              }
            }
            if (address6 !== FAILURE) {
              elements3[0] = address6;
              var address7 = FAILURE;
              var index5 = this._offset, elements4 = [], address8 = null;
              while (true) {
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements4.push(address8);
                } else {
                  break;
                }
              }
              if (elements4.length >= 0) {
                address7 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                this._offset = this._offset;
              } else {
                address7 = FAILURE;
              }
              if (address7 !== FAILURE) {
                var address9 = FAILURE;
                address9 = this._read_product();
                if (address9 !== FAILURE) {
                  elements3[1] = address9;
                } else {
                  elements3 = null;
                  this._offset = index4;
                }
              } else {
                elements3 = null;
                this._offset = index4;
              }
            } else {
              elements3 = null;
              this._offset = index4;
            }
            if (elements3 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode8(this._input.substring(index4, this._offset), index4, elements3);
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeSum(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._sum[index0] = [address0, this._offset];
      return address0;
    },

    _read_product () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._product = this._cache._product || {};
      var cached = this._cache._product[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_where();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            var index4 = this._offset, elements3 = new Array(2);
            var address6 = FAILURE;
            var chunk0 = null, max0 = this._offset + 1;
            if (max0 <= this._inputSize) {
              chunk0 = this._input.substring(this._offset, max0);
            }
            if (chunk0 !== null && /^[*/]/.test(chunk0)) {
              address6 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address6 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::product', '[*/]']);
              }
            }
            if (address6 !== FAILURE) {
              elements3[0] = address6;
              var address7 = FAILURE;
              var index5 = this._offset, elements4 = [], address8 = null;
              while (true) {
                address8 = this._read_space();
                if (address8 !== FAILURE) {
                  elements4.push(address8);
                } else {
                  break;
                }
              }
              if (elements4.length >= 0) {
                address7 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                this._offset = this._offset;
              } else {
                address7 = FAILURE;
              }
              if (address7 !== FAILURE) {
                var address9 = FAILURE;
                address9 = this._read_where();
                if (address9 !== FAILURE) {
                  elements3[1] = address9;
                } else {
                  elements3 = null;
                  this._offset = index4;
                }
              } else {
                elements3 = null;
                this._offset = index4;
              }
            } else {
              elements3 = null;
              this._offset = index4;
            }
            if (elements3 === null) {
              address5 = FAILURE;
            } else {
              address5 = new TreeNode10(this._input.substring(index4, this._offset), index4, elements3);
              this._offset = this._offset;
            }
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeProduct(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._product[index0] = [address0, this._offset];
      return address0;
    },

    _read_where () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._where = this._cache._where || {};
      var cached = this._cache._where[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_in_table();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset;
          var index4 = this._offset, elements2 = new Array(1);
          var address5 = FAILURE;
          var chunk0 = null, max0 = this._offset + 5;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 !== null && chunk0.toLowerCase() === 'where'.toLowerCase()) {
            address5 = new TreeNode(this._input.substring(this._offset, this._offset + 5), this._offset, []);
            this._offset = this._offset + 5;
          } else {
            address5 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::where', '`where`']);
            }
          }
          if (address5 !== FAILURE) {
            var address6 = FAILURE;
            var index5 = this._offset, elements3 = [], address7 = null;
            while (true) {
              address7 = this._read_space();
              if (address7 !== FAILURE) {
                elements3.push(address7);
              } else {
                break;
              }
            }
            if (elements3.length >= 0) {
              address6 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
              this._offset = this._offset;
            } else {
              address6 = FAILURE;
            }
            if (address6 !== FAILURE) {
              var address8 = FAILURE;
              address8 = this._read_expression();
              if (address8 !== FAILURE) {
                elements2[0] = address8;
                var address9 = FAILURE;
                var index6 = this._offset, elements4 = [], address10 = null;
                while (true) {
                  address10 = this._read_space();
                  if (address10 !== FAILURE) {
                    elements4.push(address10);
                  } else {
                    break;
                  }
                }
                if (elements4.length >= 0) {
                  address9 = new TreeNode(this._input.substring(index6, this._offset), index6, elements4);
                  this._offset = this._offset;
                } else {
                  address9 = FAILURE;
                }
                if (address9 !== FAILURE) {
                } else {
                  elements2 = null;
                  this._offset = index4;
                }
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address4 = FAILURE;
          } else {
            address4 = new TreeNode12(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address4 === FAILURE) {
            address4 = new TreeNode(this._input.substring(index3, index3), index3, []);
            this._offset = index3;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeWhere(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._where[index0] = [address0, this._offset];
      return address0;
    },

    _read_in_table () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._in_table = this._cache._in_table || {};
      var cached = this._cache._in_table[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_in_list();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset;
          var index4 = this._offset, elements2 = new Array(2);
          var address5 = FAILURE;
          var index5 = this._offset, elements3 = new Array(3);
          var address6 = FAILURE;
          var index6 = this._offset;
          var chunk0 = null, max0 = this._offset + 4;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 !== null && chunk0.toLowerCase() === 'not '.toLowerCase()) {
            address6 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
            this._offset = this._offset + 4;
          } else {
            address6 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::in_table', '`not `']);
            }
          }
          if (address6 === FAILURE) {
            address6 = new TreeNode(this._input.substring(index6, index6), index6, []);
            this._offset = index6;
          }
          if (address6 !== FAILURE) {
            elements3[0] = address6;
            var address7 = FAILURE;
            var index7 = this._offset;
            var chunk1 = null, max1 = this._offset + 8;
            if (max1 <= this._inputSize) {
              chunk1 = this._input.substring(this._offset, max1);
            }
            if (chunk1 !== null && chunk1.toLowerCase() === 'in table'.toLowerCase()) {
              address7 = new TreeNode(this._input.substring(this._offset, this._offset + 8), this._offset, []);
              this._offset = this._offset + 8;
            } else {
              address7 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::in_table', '`in table`']);
              }
            }
            if (address7 === FAILURE) {
              this._offset = index7;
              var chunk2 = null, max2 = this._offset + 8;
              if (max2 <= this._inputSize) {
                chunk2 = this._input.substring(this._offset, max2);
              }
              if (chunk2 !== null && chunk2.toLowerCase() === 'in Table'.toLowerCase()) {
                address7 = new TreeNode(this._input.substring(this._offset, this._offset + 8), this._offset, []);
                this._offset = this._offset + 8;
              } else {
                address7 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::in_table', '`in Table`']);
                }
              }
              if (address7 === FAILURE) {
                this._offset = index7;
              }
            }
            if (address7 !== FAILURE) {
              elements3[1] = address7;
              var address8 = FAILURE;
              var index8 = this._offset;
              var chunk3 = null, max3 = this._offset + 1;
              if (max3 <= this._inputSize) {
                chunk3 = this._input.substring(this._offset, max3);
              }
              if (chunk3 !== null && chunk3.toLowerCase() === 's'.toLowerCase()) {
                address8 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address8 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::in_table', '`s`']);
                }
              }
              if (address8 === FAILURE) {
                address8 = new TreeNode(this._input.substring(index8, index8), index8, []);
                this._offset = index8;
              }
              if (address8 !== FAILURE) {
                elements3[2] = address8;
              } else {
                elements3 = null;
                this._offset = index5;
              }
            } else {
              elements3 = null;
              this._offset = index5;
            }
          } else {
            elements3 = null;
            this._offset = index5;
          }
          if (elements3 === null) {
            address5 = FAILURE;
          } else {
            address5 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
            this._offset = this._offset;
          }
          if (address5 !== FAILURE) {
            elements2[0] = address5;
            var address9 = FAILURE;
            var index9 = this._offset, elements4 = [], address10 = null;
            while (true) {
              address10 = this._read_space();
              if (address10 !== FAILURE) {
                elements4.push(address10);
              } else {
                break;
              }
            }
            if (elements4.length >= 0) {
              address9 = new TreeNode(this._input.substring(index9, this._offset), index9, elements4);
              this._offset = this._offset;
            } else {
              address9 = FAILURE;
            }
            if (address9 !== FAILURE) {
              var address11 = FAILURE;
              address11 = this._read_lparen();
              if (address11 !== FAILURE) {
                var address12 = FAILURE;
                address12 = this._read_table_list();
                if (address12 !== FAILURE) {
                  elements2[1] = address12;
                  var address13 = FAILURE;
                  address13 = this._read_rparen();
                  if (address13 !== FAILURE) {
                    var address14 = FAILURE;
                    var index10 = this._offset, elements5 = [], address15 = null;
                    while (true) {
                      address15 = this._read_space();
                      if (address15 !== FAILURE) {
                        elements5.push(address15);
                      } else {
                        break;
                      }
                    }
                    if (elements5.length >= 0) {
                      address14 = new TreeNode(this._input.substring(index10, this._offset), index10, elements5);
                      this._offset = this._offset;
                    } else {
                      address14 = FAILURE;
                    }
                    if (address14 !== FAILURE) {
                    } else {
                      elements2 = null;
                      this._offset = index4;
                    }
                  } else {
                    elements2 = null;
                    this._offset = index4;
                  }
                } else {
                  elements2 = null;
                  this._offset = index4;
                }
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address4 = FAILURE;
          } else {
            address4 = new TreeNode14(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address4 === FAILURE) {
            address4 = new TreeNode(this._input.substring(index3, index3), index3, []);
            this._offset = index3;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeInTable(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._in_table[index0] = [address0, this._offset];
      return address0;
    },

    _read_in_list () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._in_list = this._cache._in_list || {};
      var cached = this._cache._in_list[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_simple_expression();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset;
          var index4 = this._offset, elements2 = new Array(2);
          var address5 = FAILURE;
          var index5 = this._offset, elements3 = new Array(2);
          var address6 = FAILURE;
          var index6 = this._offset;
          var chunk0 = null, max0 = this._offset + 4;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 !== null && chunk0.toLowerCase() === 'not '.toLowerCase()) {
            address6 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
            this._offset = this._offset + 4;
          } else {
            address6 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::in_list', '`not `']);
            }
          }
          if (address6 === FAILURE) {
            address6 = new TreeNode(this._input.substring(index6, index6), index6, []);
            this._offset = index6;
          }
          if (address6 !== FAILURE) {
            elements3[0] = address6;
            var address7 = FAILURE;
            var index7 = this._offset;
            var chunk1 = null, max1 = this._offset + 7;
            if (max1 <= this._inputSize) {
              chunk1 = this._input.substring(this._offset, max1);
            }
            if (chunk1 !== null && chunk1.toLowerCase() === 'in list'.toLowerCase()) {
              address7 = new TreeNode(this._input.substring(this._offset, this._offset + 7), this._offset, []);
              this._offset = this._offset + 7;
            } else {
              address7 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::in_list', '`in list`']);
              }
            }
            if (address7 === FAILURE) {
              this._offset = index7;
              var chunk2 = null, max2 = this._offset + 7;
              if (max2 <= this._inputSize) {
                chunk2 = this._input.substring(this._offset, max2);
              }
              if (chunk2 !== null && chunk2.toLowerCase() === 'in List'.toLowerCase()) {
                address7 = new TreeNode(this._input.substring(this._offset, this._offset + 7), this._offset, []);
                this._offset = this._offset + 7;
              } else {
                address7 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::in_list', '`in List`']);
                }
              }
              if (address7 === FAILURE) {
                this._offset = index7;
              }
            }
            if (address7 !== FAILURE) {
              elements3[1] = address7;
            } else {
              elements3 = null;
              this._offset = index5;
            }
          } else {
            elements3 = null;
            this._offset = index5;
          }
          if (elements3 === null) {
            address5 = FAILURE;
          } else {
            address5 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
            this._offset = this._offset;
          }
          if (address5 !== FAILURE) {
            elements2[0] = address5;
            var address8 = FAILURE;
            var index8 = this._offset, elements4 = [], address9 = null;
            while (true) {
              address9 = this._read_space();
              if (address9 !== FAILURE) {
                elements4.push(address9);
              } else {
                break;
              }
            }
            if (elements4.length >= 0) {
              address8 = new TreeNode(this._input.substring(index8, this._offset), index8, elements4);
              this._offset = this._offset;
            } else {
              address8 = FAILURE;
            }
            if (address8 !== FAILURE) {
              var address10 = FAILURE;
              address10 = this._read_lparen();
              if (address10 !== FAILURE) {
                var address11 = FAILURE;
                address11 = this._read_code_list();
                if (address11 !== FAILURE) {
                  elements2[1] = address11;
                  var address12 = FAILURE;
                  address12 = this._read_rparen();
                  if (address12 !== FAILURE) {
                    var address13 = FAILURE;
                    var index9 = this._offset, elements5 = [], address14 = null;
                    while (true) {
                      address14 = this._read_space();
                      if (address14 !== FAILURE) {
                        elements5.push(address14);
                      } else {
                        break;
                      }
                    }
                    if (elements5.length >= 0) {
                      address13 = new TreeNode(this._input.substring(index9, this._offset), index9, elements5);
                      this._offset = this._offset;
                    } else {
                      address13 = FAILURE;
                    }
                    if (address13 !== FAILURE) {
                    } else {
                      elements2 = null;
                      this._offset = index4;
                    }
                  } else {
                    elements2 = null;
                    this._offset = index4;
                  }
                } else {
                  elements2 = null;
                  this._offset = index4;
                }
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address4 = FAILURE;
          } else {
            address4 = new TreeNode16(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address4 === FAILURE) {
            address4 = new TreeNode(this._input.substring(index3, index3), index3, []);
            this._offset = index3;
          }
          if (address4 !== FAILURE) {
            elements0[1] = address4;
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeInList(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._in_list[index0] = [address0, this._offset];
      return address0;
    },

    _read_simple_expression () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._simple_expression = this._cache._simple_expression || {};
      var cached = this._cache._simple_expression[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      address0 = this._read_function_call();
      if (address0 === FAILURE) {
        this._offset = index1;
        address0 = this._read_lookup();
        if (address0 === FAILURE) {
          this._offset = index1;
          address0 = this._read_variable();
          if (address0 === FAILURE) {
            this._offset = index1;
            address0 = this._read_string();
            if (address0 === FAILURE) {
              this._offset = index1;
              address0 = this._read_number();
              if (address0 === FAILURE) {
                this._offset = index1;
                var index2 = this._offset, elements0 = new Array(2);
                var address1 = FAILURE;
                address1 = this._read_lparen();
                if (address1 !== FAILURE) {
                  elements0[0] = address1;
                  var address2 = FAILURE;
                  address2 = this._read_expression();
                  if (address2 !== FAILURE) {
                    elements0[1] = address2;
                    var address3 = FAILURE;
                    var index3 = this._offset, elements1 = [], address4 = null;
                    while (true) {
                      address4 = this._read_space();
                      if (address4 !== FAILURE) {
                        elements1.push(address4);
                      } else {
                        break;
                      }
                    }
                    if (elements1.length >= 0) {
                      address3 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
                      this._offset = this._offset;
                    } else {
                      address3 = FAILURE;
                    }
                    if (address3 !== FAILURE) {
                      var address5 = FAILURE;
                      address5 = this._read_rparen();
                      if (address5 !== FAILURE) {
                        var address6 = FAILURE;
                        var index4 = this._offset, elements2 = [], address7 = null;
                        while (true) {
                          address7 = this._read_space();
                          if (address7 !== FAILURE) {
                            elements2.push(address7);
                          } else {
                            break;
                          }
                        }
                        if (elements2.length >= 0) {
                          address6 = new TreeNode(this._input.substring(index4, this._offset), index4, elements2);
                          this._offset = this._offset;
                        } else {
                          address6 = FAILURE;
                        }
                        if (address6 !== FAILURE) {
                        } else {
                          elements0 = null;
                          this._offset = index2;
                        }
                      } else {
                        elements0 = null;
                        this._offset = index2;
                      }
                    } else {
                      elements0 = null;
                      this._offset = index2;
                    }
                  } else {
                    elements0 = null;
                    this._offset = index2;
                  }
                } else {
                  elements0 = null;
                  this._offset = index2;
                }
                if (elements0 === null) {
                  address0 = FAILURE;
                } else {
                  address0 = this._actions.makeSimpleExpression(this._input, index2, this._offset, elements0);
                  this._offset = this._offset;
                }
                if (address0 === FAILURE) {
                  this._offset = index1;
                }
              }
            }
          }
        }
      }
      this._cache._simple_expression[index0] = [address0, this._offset];
      return address0;
    },

    _read_variable () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._variable = this._cache._variable || {};
      var cached = this._cache._variable[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(1);
      var address1 = FAILURE;
      address1 = this._read_identifier();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeVariable(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._variable[index0] = [address0, this._offset];
      return address0;
    },

    _read_lookup () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._lookup = this._cache._lookup || {};
      var cached = this._cache._lookup[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(3);
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 6;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && chunk0.toLowerCase() === 'lookup'.toLowerCase()) {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 6), this._offset, []);
        this._offset = this._offset + 6;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::lookup', '`lookup`']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          address4 = this._read_lparen();
          if (address4 !== FAILURE) {
            var address5 = FAILURE;
            address5 = this._read_variable();
            if (address5 !== FAILURE) {
              elements0[0] = address5;
              var address6 = FAILURE;
              var index3 = this._offset, elements2 = [], address7 = null;
              while (true) {
                address7 = this._read_space();
                if (address7 !== FAILURE) {
                  elements2.push(address7);
                } else {
                  break;
                }
              }
              if (elements2.length >= 0) {
                address6 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
                this._offset = this._offset;
              } else {
                address6 = FAILURE;
              }
              if (address6 !== FAILURE) {
                var address8 = FAILURE;
                var chunk1 = null, max1 = this._offset + 2;
                if (max1 <= this._inputSize) {
                  chunk1 = this._input.substring(this._offset, max1);
                }
                if (chunk1 !== null && chunk1.toLowerCase() === 'in'.toLowerCase()) {
                  address8 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
                  this._offset = this._offset + 2;
                } else {
                  address8 = FAILURE;
                  if (this._offset > this._failure) {
                    this._failure = this._offset;
                    this._expected = [];
                  }
                  if (this._offset === this._failure) {
                    this._expected.push(['Medcodelogic::lookup', '`in`']);
                  }
                }
                if (address8 !== FAILURE) {
                  var address9 = FAILURE;
                  var index4 = this._offset, elements3 = [], address10 = null;
                  while (true) {
                    address10 = this._read_space();
                    if (address10 !== FAILURE) {
                      elements3.push(address10);
                    } else {
                      break;
                    }
                  }
                  if (elements3.length >= 0) {
                    address9 = new TreeNode(this._input.substring(index4, this._offset), index4, elements3);
                    this._offset = this._offset;
                  } else {
                    address9 = FAILURE;
                  }
                  if (address9 !== FAILURE) {
                    var address11 = FAILURE;
                    address11 = this._read_table();
                    if (address11 !== FAILURE) {
                      elements0[1] = address11;
                      var address12 = FAILURE;
                      var index5 = this._offset, elements4 = [], address13 = null;
                      while (true) {
                        address13 = this._read_space();
                        if (address13 !== FAILURE) {
                          elements4.push(address13);
                        } else {
                          break;
                        }
                      }
                      if (elements4.length >= 0) {
                        address12 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                        this._offset = this._offset;
                      } else {
                        address12 = FAILURE;
                      }
                      if (address12 !== FAILURE) {
                        var address14 = FAILURE;
                        var index6 = this._offset;
                        var index7 = this._offset, elements5 = new Array(1);
                        var address15 = FAILURE;
                        var chunk2 = null, max2 = this._offset + 2;
                        if (max2 <= this._inputSize) {
                          chunk2 = this._input.substring(this._offset, max2);
                        }
                        if (chunk2 !== null && chunk2.toLowerCase() === 'by'.toLowerCase()) {
                          address15 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
                          this._offset = this._offset + 2;
                        } else {
                          address15 = FAILURE;
                          if (this._offset > this._failure) {
                            this._failure = this._offset;
                            this._expected = [];
                          }
                          if (this._offset === this._failure) {
                            this._expected.push(['Medcodelogic::lookup', '`by`']);
                          }
                        }
                        if (address15 !== FAILURE) {
                          var address16 = FAILURE;
                          var index8 = this._offset, elements6 = [], address17 = null;
                          while (true) {
                            address17 = this._read_space();
                            if (address17 !== FAILURE) {
                              elements6.push(address17);
                            } else {
                              break;
                            }
                          }
                          if (elements6.length >= 1) {
                            address16 = new TreeNode(this._input.substring(index8, this._offset), index8, elements6);
                            this._offset = this._offset;
                          } else {
                            address16 = FAILURE;
                          }
                          if (address16 !== FAILURE) {
                            var address18 = FAILURE;
                            address18 = this._read_variable();
                            if (address18 !== FAILURE) {
                              elements5[0] = address18;
                              var address19 = FAILURE;
                              var index9 = this._offset, elements7 = [], address20 = null;
                              while (true) {
                                address20 = this._read_space();
                                if (address20 !== FAILURE) {
                                  elements7.push(address20);
                                } else {
                                  break;
                                }
                              }
                              if (elements7.length >= 0) {
                                address19 = new TreeNode(this._input.substring(index9, this._offset), index9, elements7);
                                this._offset = this._offset;
                              } else {
                                address19 = FAILURE;
                              }
                              if (address19 !== FAILURE) {
                              } else {
                                elements5 = null;
                                this._offset = index7;
                              }
                            } else {
                              elements5 = null;
                              this._offset = index7;
                            }
                          } else {
                            elements5 = null;
                            this._offset = index7;
                          }
                        } else {
                          elements5 = null;
                          this._offset = index7;
                        }
                        if (elements5 === null) {
                          address14 = FAILURE;
                        } else {
                          address14 = new TreeNode20(this._input.substring(index7, this._offset), index7, elements5);
                          this._offset = this._offset;
                        }
                        if (address14 === FAILURE) {
                          address14 = new TreeNode(this._input.substring(index6, index6), index6, []);
                          this._offset = index6;
                        }
                        if (address14 !== FAILURE) {
                          elements0[2] = address14;
                          var address21 = FAILURE;
                          address21 = this._read_rparen();
                          if (address21 !== FAILURE) {
                            var address22 = FAILURE;
                            var index10 = this._offset, elements8 = [], address23 = null;
                            while (true) {
                              address23 = this._read_space();
                              if (address23 !== FAILURE) {
                                elements8.push(address23);
                              } else {
                                break;
                              }
                            }
                            if (elements8.length >= 0) {
                              address22 = new TreeNode(this._input.substring(index10, this._offset), index10, elements8);
                              this._offset = this._offset;
                            } else {
                              address22 = FAILURE;
                            }
                            if (address22 !== FAILURE) {
                            } else {
                              elements0 = null;
                              this._offset = index1;
                            }
                          } else {
                            elements0 = null;
                            this._offset = index1;
                          }
                        } else {
                          elements0 = null;
                          this._offset = index1;
                        }
                      } else {
                        elements0 = null;
                        this._offset = index1;
                      }
                    } else {
                      elements0 = null;
                      this._offset = index1;
                    }
                  } else {
                    elements0 = null;
                    this._offset = index1;
                  }
                } else {
                  elements0 = null;
                  this._offset = index1;
                }
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeLookup(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._lookup[index0] = [address0, this._offset];
      return address0;
    },

    _read_function_call () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._function_call = this._cache._function_call || {};
      var cached = this._cache._function_call[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_identifier();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          address4 = this._read_lparen();
          if (address4 !== FAILURE) {
            var address5 = FAILURE;
            address5 = this._read_list();
            if (address5 !== FAILURE) {
              elements0[1] = address5;
              var address6 = FAILURE;
              address6 = this._read_rparen();
              if (address6 !== FAILURE) {
                var address7 = FAILURE;
                var index3 = this._offset, elements2 = [], address8 = null;
                while (true) {
                  address8 = this._read_space();
                  if (address8 !== FAILURE) {
                    elements2.push(address8);
                  } else {
                    break;
                  }
                }
                if (elements2.length >= 0) {
                  address7 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
                  this._offset = this._offset;
                } else {
                  address7 = FAILURE;
                }
                if (address7 !== FAILURE) {
                } else {
                  elements0 = null;
                  this._offset = index1;
                }
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeFunctionCall(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._function_call[index0] = [address0, this._offset];
      return address0;
    },

    _read_list () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._list = this._cache._list || {};
      var cached = this._cache._list[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var index2 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_expression();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index3 = this._offset, elements1 = [], address3 = null;
        while (true) {
          var index4 = this._offset, elements2 = new Array(1);
          var address4 = FAILURE;
          var chunk0 = null, max0 = this._offset + 1;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 === ',') {
            address4 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address4 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::list', '","']);
            }
          }
          if (address4 !== FAILURE) {
            var address5 = FAILURE;
            var index5 = this._offset, elements3 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements3.push(address6);
              } else {
                break;
              }
            }
            if (elements3.length >= 0) {
              address5 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              address7 = this._read_expression();
              if (address7 !== FAILURE) {
                elements2[0] = address7;
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address3 = FAILURE;
          } else {
            address3 = new TreeNode23(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          elements0[1] = address2;
        } else {
          elements0 = null;
          this._offset = index2;
        }
      } else {
        elements0 = null;
        this._offset = index2;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeList(this._input, index2, this._offset, elements0);
        this._offset = this._offset;
      }
      if (address0 === FAILURE) {
        address0 = new TreeNode(this._input.substring(index1, index1), index1, []);
        this._offset = index1;
      }
      this._cache._list[index0] = [address0, this._offset];
      return address0;
    },

    _read_table_list () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._table_list = this._cache._table_list || {};
      var cached = this._cache._table_list[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var index2 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      address1 = this._read_table();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index3 = this._offset, elements1 = [], address3 = null;
        while (true) {
          var index4 = this._offset, elements2 = new Array(1);
          var address4 = FAILURE;
          var chunk0 = null, max0 = this._offset + 1;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 === ',') {
            address4 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address4 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::table_list', '","']);
            }
          }
          if (address4 !== FAILURE) {
            var address5 = FAILURE;
            var index5 = this._offset, elements3 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements3.push(address6);
              } else {
                break;
              }
            }
            if (elements3.length >= 0) {
              address5 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              address7 = this._read_table();
              if (address7 !== FAILURE) {
                elements2[0] = address7;
              } else {
                elements2 = null;
                this._offset = index4;
              }
            } else {
              elements2 = null;
              this._offset = index4;
            }
          } else {
            elements2 = null;
            this._offset = index4;
          }
          if (elements2 === null) {
            address3 = FAILURE;
          } else {
            address3 = new TreeNode25(this._input.substring(index4, this._offset), index4, elements2);
            this._offset = this._offset;
          }
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          elements0[1] = address2;
        } else {
          elements0 = null;
          this._offset = index2;
        }
      } else {
        elements0 = null;
        this._offset = index2;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeTableList(this._input, index2, this._offset, elements0);
        this._offset = this._offset;
      }
      if (address0 === FAILURE) {
        address0 = new TreeNode(this._input.substring(index1, index1), index1, []);
        this._offset = index1;
      }
      this._cache._table_list[index0] = [address0, this._offset];
      return address0;
    },

    _read_table () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._table = this._cache._table || {};
      var cached = this._cache._table[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(1);
      var address1 = FAILURE;
      var index2 = this._offset, elements1 = new Array(2);
      var address2 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && /^[_a-zA-Z]/.test(chunk0)) {
        address2 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address2 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::table', '[_a-zA-Z]']);
        }
      }
      if (address2 !== FAILURE) {
        elements1[0] = address2;
        var address3 = FAILURE;
        var index3 = this._offset, elements2 = [], address4 = null;
        while (true) {
          var chunk1 = null, max1 = this._offset + 1;
          if (max1 <= this._inputSize) {
            chunk1 = this._input.substring(this._offset, max1);
          }
          if (chunk1 !== null && /^[._a-zA-Z0-9]/.test(chunk1)) {
            address4 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address4 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::table', '[._a-zA-Z0-9]']);
            }
          }
          if (address4 !== FAILURE) {
            elements2.push(address4);
          } else {
            break;
          }
        }
        if (elements2.length >= 0) {
          address3 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
          this._offset = this._offset;
        } else {
          address3 = FAILURE;
        }
        if (address3 !== FAILURE) {
          elements1[1] = address3;
        } else {
          elements1 = null;
          this._offset = index2;
        }
      } else {
        elements1 = null;
        this._offset = index2;
      }
      if (elements1 === null) {
        address1 = FAILURE;
      } else {
        address1 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
        this._offset = this._offset;
      }
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address5 = FAILURE;
        var index4 = this._offset, elements3 = [], address6 = null;
        while (true) {
          address6 = this._read_space();
          if (address6 !== FAILURE) {
            elements3.push(address6);
          } else {
            break;
          }
        }
        if (elements3.length >= 0) {
          address5 = new TreeNode(this._input.substring(index4, this._offset), index4, elements3);
          this._offset = this._offset;
        } else {
          address5 = FAILURE;
        }
        if (address5 !== FAILURE) {
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeTable(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._table[index0] = [address0, this._offset];
      return address0;
    },

    _read_code_list () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._code_list = this._cache._code_list || {};
      var cached = this._cache._code_list[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var index2 = this._offset, elements0 = new Array(2);
      var address1 = FAILURE;
      var index3 = this._offset;
      address1 = this._read_code();
      if (address1 === FAILURE) {
        this._offset = index3;
        address1 = this._read_string();
        if (address1 === FAILURE) {
          this._offset = index3;
        }
      }
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index4 = this._offset, elements1 = [], address3 = null;
        while (true) {
          var index5 = this._offset, elements2 = new Array(1);
          var address4 = FAILURE;
          var chunk0 = null, max0 = this._offset + 1;
          if (max0 <= this._inputSize) {
            chunk0 = this._input.substring(this._offset, max0);
          }
          if (chunk0 === ',') {
            address4 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address4 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::code_list', '","']);
            }
          }
          if (address4 !== FAILURE) {
            var address5 = FAILURE;
            var index6 = this._offset, elements3 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements3.push(address6);
              } else {
                break;
              }
            }
            if (elements3.length >= 0) {
              address5 = new TreeNode(this._input.substring(index6, this._offset), index6, elements3);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              var index7 = this._offset;
              address7 = this._read_code();
              if (address7 === FAILURE) {
                this._offset = index7;
                address7 = this._read_string();
                if (address7 === FAILURE) {
                  this._offset = index7;
                }
              }
              if (address7 !== FAILURE) {
                elements2[0] = address7;
              } else {
                elements2 = null;
                this._offset = index5;
              }
            } else {
              elements2 = null;
              this._offset = index5;
            }
          } else {
            elements2 = null;
            this._offset = index5;
          }
          if (elements2 === null) {
            address3 = FAILURE;
          } else {
            address3 = new TreeNode(this._input.substring(index5, this._offset), index5, elements2);
            this._offset = this._offset;
          }
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index4, this._offset), index4, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          elements0[1] = address2;
        } else {
          elements0 = null;
          this._offset = index2;
        }
      } else {
        elements0 = null;
        this._offset = index2;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeCodeList(this._input, index2, this._offset, elements0);
        this._offset = this._offset;
      }
      if (address0 === FAILURE) {
        address0 = new TreeNode(this._input.substring(index1, index1), index1, []);
        this._offset = index1;
      }
      this._cache._code_list[index0] = [address0, this._offset];
      return address0;
    },

    _read_number () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._number = this._cache._number || {};
      var cached = this._cache._number[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(4);
      var address1 = FAILURE;
      var index2 = this._offset;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === '-') {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::number', '"-"']);
        }
      }
      if (address1 === FAILURE) {
        address1 = new TreeNode(this._input.substring(index2, index2), index2, []);
        this._offset = index2;
      }
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index3 = this._offset;
        var chunk1 = null, max1 = this._offset + 1;
        if (max1 <= this._inputSize) {
          chunk1 = this._input.substring(this._offset, max1);
        }
        if (chunk1 === '0') {
          address2 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
          this._offset = this._offset + 1;
        } else {
          address2 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push(['Medcodelogic::number', '"0"']);
          }
        }
        if (address2 === FAILURE) {
          this._offset = index3;
          var index4 = this._offset, elements1 = new Array(2);
          var address3 = FAILURE;
          var chunk2 = null, max2 = this._offset + 1;
          if (max2 <= this._inputSize) {
            chunk2 = this._input.substring(this._offset, max2);
          }
          if (chunk2 !== null && /^[1-9]/.test(chunk2)) {
            address3 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address3 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::number', '[1-9]']);
            }
          }
          if (address3 !== FAILURE) {
            elements1[0] = address3;
            var address4 = FAILURE;
            var index5 = this._offset, elements2 = [], address5 = null;
            while (true) {
              var chunk3 = null, max3 = this._offset + 1;
              if (max3 <= this._inputSize) {
                chunk3 = this._input.substring(this._offset, max3);
              }
              if (chunk3 !== null && /^[0-9]/.test(chunk3)) {
                address5 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address5 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::number', '[0-9]']);
                }
              }
              if (address5 !== FAILURE) {
                elements2.push(address5);
              } else {
                break;
              }
            }
            if (elements2.length >= 0) {
              address4 = new TreeNode(this._input.substring(index5, this._offset), index5, elements2);
              this._offset = this._offset;
            } else {
              address4 = FAILURE;
            }
            if (address4 !== FAILURE) {
              elements1[1] = address4;
            } else {
              elements1 = null;
              this._offset = index4;
            }
          } else {
            elements1 = null;
            this._offset = index4;
          }
          if (elements1 === null) {
            address2 = FAILURE;
          } else {
            address2 = new TreeNode(this._input.substring(index4, this._offset), index4, elements1);
            this._offset = this._offset;
          }
          if (address2 === FAILURE) {
            this._offset = index3;
          }
        }
        if (address2 !== FAILURE) {
          elements0[1] = address2;
          var address6 = FAILURE;
          var index6 = this._offset;
          var index7 = this._offset, elements3 = new Array(2);
          var address7 = FAILURE;
          var chunk4 = null, max4 = this._offset + 1;
          if (max4 <= this._inputSize) {
            chunk4 = this._input.substring(this._offset, max4);
          }
          if (chunk4 === '.') {
            address7 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address7 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::number', '"."']);
            }
          }
          if (address7 !== FAILURE) {
            elements3[0] = address7;
            var address8 = FAILURE;
            var index8 = this._offset, elements4 = [], address9 = null;
            while (true) {
              var chunk5 = null, max5 = this._offset + 1;
              if (max5 <= this._inputSize) {
                chunk5 = this._input.substring(this._offset, max5);
              }
              if (chunk5 !== null && /^[0-9]/.test(chunk5)) {
                address9 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address9 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::number', '[0-9]']);
                }
              }
              if (address9 !== FAILURE) {
                elements4.push(address9);
              } else {
                break;
              }
            }
            if (elements4.length >= 1) {
              address8 = new TreeNode(this._input.substring(index8, this._offset), index8, elements4);
              this._offset = this._offset;
            } else {
              address8 = FAILURE;
            }
            if (address8 !== FAILURE) {
              elements3[1] = address8;
            } else {
              elements3 = null;
              this._offset = index7;
            }
          } else {
            elements3 = null;
            this._offset = index7;
          }
          if (elements3 === null) {
            address6 = FAILURE;
          } else {
            address6 = new TreeNode(this._input.substring(index7, this._offset), index7, elements3);
            this._offset = this._offset;
          }
          if (address6 === FAILURE) {
            address6 = new TreeNode(this._input.substring(index6, index6), index6, []);
            this._offset = index6;
          }
          if (address6 !== FAILURE) {
            elements0[2] = address6;
            var address10 = FAILURE;
            var index9 = this._offset;
            var index10 = this._offset, elements5 = new Array(3);
            var address11 = FAILURE;
            var index11 = this._offset;
            var chunk6 = null, max6 = this._offset + 1;
            if (max6 <= this._inputSize) {
              chunk6 = this._input.substring(this._offset, max6);
            }
            if (chunk6 === 'e') {
              address11 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address11 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::number', '"e"']);
              }
            }
            if (address11 === FAILURE) {
              this._offset = index11;
              var chunk7 = null, max7 = this._offset + 1;
              if (max7 <= this._inputSize) {
                chunk7 = this._input.substring(this._offset, max7);
              }
              if (chunk7 === 'E') {
                address11 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address11 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::number', '"E"']);
                }
              }
              if (address11 === FAILURE) {
                this._offset = index11;
              }
            }
            if (address11 !== FAILURE) {
              elements5[0] = address11;
              var address12 = FAILURE;
              var index12 = this._offset;
              var chunk8 = null, max8 = this._offset + 1;
              if (max8 <= this._inputSize) {
                chunk8 = this._input.substring(this._offset, max8);
              }
              if (chunk8 === '+') {
                address12 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address12 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::number', '"+"']);
                }
              }
              if (address12 === FAILURE) {
                this._offset = index12;
                var chunk9 = null, max9 = this._offset + 1;
                if (max9 <= this._inputSize) {
                  chunk9 = this._input.substring(this._offset, max9);
                }
                if (chunk9 === '-') {
                  address12 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                  this._offset = this._offset + 1;
                } else {
                  address12 = FAILURE;
                  if (this._offset > this._failure) {
                    this._failure = this._offset;
                    this._expected = [];
                  }
                  if (this._offset === this._failure) {
                    this._expected.push(['Medcodelogic::number', '"-"']);
                  }
                }
                if (address12 === FAILURE) {
                  this._offset = index12;
                  var chunk10 = null, max10 = this._offset + 0;
                  if (max10 <= this._inputSize) {
                    chunk10 = this._input.substring(this._offset, max10);
                  }
                  if (chunk10 === '') {
                    address12 = new TreeNode(this._input.substring(this._offset, this._offset + 0), this._offset, []);
                    this._offset = this._offset + 0;
                  } else {
                    address12 = FAILURE;
                    if (this._offset > this._failure) {
                      this._failure = this._offset;
                      this._expected = [];
                    }
                    if (this._offset === this._failure) {
                      this._expected.push(['Medcodelogic::number', '""']);
                    }
                  }
                  if (address12 === FAILURE) {
                    this._offset = index12;
                  }
                }
              }
              if (address12 !== FAILURE) {
                elements5[1] = address12;
                var address13 = FAILURE;
                var index13 = this._offset, elements6 = [], address14 = null;
                while (true) {
                  var chunk11 = null, max11 = this._offset + 1;
                  if (max11 <= this._inputSize) {
                    chunk11 = this._input.substring(this._offset, max11);
                  }
                  if (chunk11 !== null && /^[0-9]/.test(chunk11)) {
                    address14 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                    this._offset = this._offset + 1;
                  } else {
                    address14 = FAILURE;
                    if (this._offset > this._failure) {
                      this._failure = this._offset;
                      this._expected = [];
                    }
                    if (this._offset === this._failure) {
                      this._expected.push(['Medcodelogic::number', '[0-9]']);
                    }
                  }
                  if (address14 !== FAILURE) {
                    elements6.push(address14);
                  } else {
                    break;
                  }
                }
                if (elements6.length >= 1) {
                  address13 = new TreeNode(this._input.substring(index13, this._offset), index13, elements6);
                  this._offset = this._offset;
                } else {
                  address13 = FAILURE;
                }
                if (address13 !== FAILURE) {
                  elements5[2] = address13;
                } else {
                  elements5 = null;
                  this._offset = index10;
                }
              } else {
                elements5 = null;
                this._offset = index10;
              }
            } else {
              elements5 = null;
              this._offset = index10;
            }
            if (elements5 === null) {
              address10 = FAILURE;
            } else {
              address10 = new TreeNode(this._input.substring(index10, this._offset), index10, elements5);
              this._offset = this._offset;
            }
            if (address10 === FAILURE) {
              address10 = new TreeNode(this._input.substring(index9, index9), index9, []);
              this._offset = index9;
            }
            if (address10 !== FAILURE) {
              elements0[3] = address10;
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeNumberConstant(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._number[index0] = [address0, this._offset];
      return address0;
    },

    _read_string () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._string = this._cache._string || {};
      var cached = this._cache._string[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(1);
      var address1 = FAILURE;
      var index2 = this._offset;
      var index3 = this._offset, elements1 = new Array(3);
      var address2 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === '"') {
        address2 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address2 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::string', '\'"\'']);
        }
      }
      if (address2 !== FAILURE) {
        elements1[0] = address2;
        var address3 = FAILURE;
        var index4 = this._offset, elements2 = [], address4 = null;
        while (true) {
          var index5 = this._offset;
          var index6 = this._offset, elements3 = new Array(2);
          var address5 = FAILURE;
          var chunk1 = null, max1 = this._offset + 1;
          if (max1 <= this._inputSize) {
            chunk1 = this._input.substring(this._offset, max1);
          }
          if (chunk1 === '\\') {
            address5 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address5 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::string', '"\\\\"']);
            }
          }
          if (address5 !== FAILURE) {
            elements3[0] = address5;
            var address6 = FAILURE;
            if (this._offset < this._inputSize) {
              address6 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address6 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::string', '<any char>']);
              }
            }
            if (address6 !== FAILURE) {
              elements3[1] = address6;
            } else {
              elements3 = null;
              this._offset = index6;
            }
          } else {
            elements3 = null;
            this._offset = index6;
          }
          if (elements3 === null) {
            address4 = FAILURE;
          } else {
            address4 = new TreeNode(this._input.substring(index6, this._offset), index6, elements3);
            this._offset = this._offset;
          }
          if (address4 === FAILURE) {
            this._offset = index5;
            var chunk2 = null, max2 = this._offset + 1;
            if (max2 <= this._inputSize) {
              chunk2 = this._input.substring(this._offset, max2);
            }
            if (chunk2 !== null && /^[^"]/.test(chunk2)) {
              address4 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address4 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::string', '[^"]']);
              }
            }
            if (address4 === FAILURE) {
              this._offset = index5;
            }
          }
          if (address4 !== FAILURE) {
            elements2.push(address4);
          } else {
            break;
          }
        }
        if (elements2.length >= 0) {
          address3 = new TreeNode(this._input.substring(index4, this._offset), index4, elements2);
          this._offset = this._offset;
        } else {
          address3 = FAILURE;
        }
        if (address3 !== FAILURE) {
          elements1[1] = address3;
          var address7 = FAILURE;
          var chunk3 = null, max3 = this._offset + 1;
          if (max3 <= this._inputSize) {
            chunk3 = this._input.substring(this._offset, max3);
          }
          if (chunk3 === '"') {
            address7 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address7 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::string', '\'"\'']);
            }
          }
          if (address7 !== FAILURE) {
            elements1[2] = address7;
          } else {
            elements1 = null;
            this._offset = index3;
          }
        } else {
          elements1 = null;
          this._offset = index3;
        }
      } else {
        elements1 = null;
        this._offset = index3;
      }
      if (elements1 === null) {
        address1 = FAILURE;
      } else {
        address1 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
        this._offset = this._offset;
      }
      if (address1 === FAILURE) {
        this._offset = index2;
        var index7 = this._offset, elements4 = new Array(3);
        var address8 = FAILURE;
        var chunk4 = null, max4 = this._offset + 1;
        if (max4 <= this._inputSize) {
          chunk4 = this._input.substring(this._offset, max4);
        }
        if (chunk4 === '\'') {
          address8 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
          this._offset = this._offset + 1;
        } else {
          address8 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push(['Medcodelogic::string', '"\'"']);
          }
        }
        if (address8 !== FAILURE) {
          elements4[0] = address8;
          var address9 = FAILURE;
          var index8 = this._offset, elements5 = [], address10 = null;
          while (true) {
            var index9 = this._offset;
            var index10 = this._offset, elements6 = new Array(2);
            var address11 = FAILURE;
            var chunk5 = null, max5 = this._offset + 1;
            if (max5 <= this._inputSize) {
              chunk5 = this._input.substring(this._offset, max5);
            }
            if (chunk5 === '\\') {
              address11 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address11 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::string', '"\\\\"']);
              }
            }
            if (address11 !== FAILURE) {
              elements6[0] = address11;
              var address12 = FAILURE;
              if (this._offset < this._inputSize) {
                address12 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address12 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::string', '<any char>']);
                }
              }
              if (address12 !== FAILURE) {
                elements6[1] = address12;
              } else {
                elements6 = null;
                this._offset = index10;
              }
            } else {
              elements6 = null;
              this._offset = index10;
            }
            if (elements6 === null) {
              address10 = FAILURE;
            } else {
              address10 = new TreeNode(this._input.substring(index10, this._offset), index10, elements6);
              this._offset = this._offset;
            }
            if (address10 === FAILURE) {
              this._offset = index9;
              var chunk6 = null, max6 = this._offset + 1;
              if (max6 <= this._inputSize) {
                chunk6 = this._input.substring(this._offset, max6);
              }
              if (chunk6 !== null && /^[^']/.test(chunk6)) {
                address10 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address10 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::string', '[^\']']);
                }
              }
              if (address10 === FAILURE) {
                this._offset = index9;
              }
            }
            if (address10 !== FAILURE) {
              elements5.push(address10);
            } else {
              break;
            }
          }
          if (elements5.length >= 0) {
            address9 = new TreeNode(this._input.substring(index8, this._offset), index8, elements5);
            this._offset = this._offset;
          } else {
            address9 = FAILURE;
          }
          if (address9 !== FAILURE) {
            elements4[1] = address9;
            var address13 = FAILURE;
            var chunk7 = null, max7 = this._offset + 1;
            if (max7 <= this._inputSize) {
              chunk7 = this._input.substring(this._offset, max7);
            }
            if (chunk7 === '\'') {
              address13 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address13 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::string', '"\'"']);
              }
            }
            if (address13 !== FAILURE) {
              elements4[2] = address13;
            } else {
              elements4 = null;
              this._offset = index7;
            }
          } else {
            elements4 = null;
            this._offset = index7;
          }
        } else {
          elements4 = null;
          this._offset = index7;
        }
        if (elements4 === null) {
          address1 = FAILURE;
        } else {
          address1 = new TreeNode(this._input.substring(index7, this._offset), index7, elements4);
          this._offset = this._offset;
        }
        if (address1 === FAILURE) {
          this._offset = index2;
        }
      }
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address14 = FAILURE;
        var index11 = this._offset, elements7 = [], address15 = null;
        while (true) {
          address15 = this._read_space();
          if (address15 !== FAILURE) {
            elements7.push(address15);
          } else {
            break;
          }
        }
        if (elements7.length >= 0) {
          address14 = new TreeNode(this._input.substring(index11, this._offset), index11, elements7);
          this._offset = this._offset;
        } else {
          address14 = FAILURE;
        }
        if (address14 !== FAILURE) {
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeStringConstant(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._string[index0] = [address0, this._offset];
      return address0;
    },

    _read_operator () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._operator = this._cache._operator || {};
      var cached = this._cache._operator[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var chunk0 = null, max0 = this._offset + 2;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === '>=') {
        address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
        this._offset = this._offset + 2;
      } else {
        address0 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::operator', '">="']);
        }
      }
      if (address0 === FAILURE) {
        this._offset = index1;
        var chunk1 = null, max1 = this._offset + 2;
        if (max1 <= this._inputSize) {
          chunk1 = this._input.substring(this._offset, max1);
        }
        if (chunk1 === '<=') {
          address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
          this._offset = this._offset + 2;
        } else {
          address0 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push(['Medcodelogic::operator', '"<="']);
          }
        }
        if (address0 === FAILURE) {
          this._offset = index1;
          var chunk2 = null, max2 = this._offset + 1;
          if (max2 <= this._inputSize) {
            chunk2 = this._input.substring(this._offset, max2);
          }
          if (chunk2 === '>') {
            address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address0 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::operator', '">"']);
            }
          }
          if (address0 === FAILURE) {
            this._offset = index1;
            var chunk3 = null, max3 = this._offset + 1;
            if (max3 <= this._inputSize) {
              chunk3 = this._input.substring(this._offset, max3);
            }
            if (chunk3 === '<') {
              address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
              this._offset = this._offset + 1;
            } else {
              address0 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::operator', '"<"']);
              }
            }
            if (address0 === FAILURE) {
              this._offset = index1;
              var chunk4 = null, max4 = this._offset + 1;
              if (max4 <= this._inputSize) {
                chunk4 = this._input.substring(this._offset, max4);
              }
              if (chunk4 === '=') {
                address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address0 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::operator', '"="']);
                }
              }
              if (address0 === FAILURE) {
                this._offset = index1;
                var chunk5 = null, max5 = this._offset + 2;
                if (max5 <= this._inputSize) {
                  chunk5 = this._input.substring(this._offset, max5);
                }
                if (chunk5 === '!=') {
                  address0 = new TreeNode(this._input.substring(this._offset, this._offset + 2), this._offset, []);
                  this._offset = this._offset + 2;
                } else {
                  address0 = FAILURE;
                  if (this._offset > this._failure) {
                    this._failure = this._offset;
                    this._expected = [];
                  }
                  if (this._offset === this._failure) {
                    this._expected.push(['Medcodelogic::operator', '"!="']);
                  }
                }
                if (address0 === FAILURE) {
                  this._offset = index1;
                }
              }
            }
          }
        }
      }
      this._cache._operator[index0] = [address0, this._offset];
      return address0;
    },

    _read_code () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._code = this._cache._code || {};
      var cached = this._cache._code[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = [];
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && /^[a-zA-Z0-9*]/.test(chunk0)) {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::code', '[a-zA-Z0-9*]']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          var chunk1 = null, max1 = this._offset + 1;
          if (max1 <= this._inputSize) {
            chunk1 = this._input.substring(this._offset, max1);
          }
          if (chunk1 !== null && /^[.a-zA-Z0-9_*]/.test(chunk1)) {
            address3 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address3 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::code', '[.a-zA-Z0-9_*]']);
            }
          }
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            address5 = this._read_space();
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeCodeConstant(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._code[index0] = [address0, this._offset];
      return address0;
    },

    _read_identifier () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._identifier = this._cache._identifier || {};
      var cached = this._cache._identifier[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = [];
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && /^[_a-zA-Z]/.test(chunk0)) {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::identifier', '[_a-zA-Z]']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          var chunk1 = null, max1 = this._offset + 1;
          if (max1 <= this._inputSize) {
            chunk1 = this._input.substring(this._offset, max1);
          }
          if (chunk1 !== null && /^[_a-zA-Z0-9]/.test(chunk1)) {
            address3 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
            this._offset = this._offset + 1;
          } else {
            address3 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::identifier', '[_a-zA-Z0-9]']);
            }
          }
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          var index3 = this._offset, elements2 = [], address5 = null;
          while (true) {
            address5 = this._read_space();
            if (address5 !== FAILURE) {
              elements2.push(address5);
            } else {
              break;
            }
          }
          if (elements2.length >= 0) {
            address4 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
            this._offset = this._offset;
          } else {
            address4 = FAILURE;
          }
          if (address4 !== FAILURE) {
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = new TreeNode(this._input.substring(index1, this._offset), index1, elements0);
        this._offset = this._offset;
      }
      this._cache._identifier[index0] = [address0, this._offset];
      return address0;
    },

    _read_lparen () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._lparen = this._cache._lparen || {};
      var cached = this._cache._lparen[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = [];
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === '(') {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::lparen', '"("']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = new TreeNode(this._input.substring(index1, this._offset), index1, elements0);
        this._offset = this._offset;
      }
      this._cache._lparen[index0] = [address0, this._offset];
      return address0;
    },

    _read_rparen () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._rparen = this._cache._rparen || {};
      var cached = this._cache._rparen[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = [];
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === ')') {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::rparen', '")"']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = new TreeNode(this._input.substring(index1, this._offset), index1, elements0);
        this._offset = this._offset;
      }
      this._cache._rparen[index0] = [address0, this._offset];
      return address0;
    },

    _read_space () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._space = this._cache._space || {};
      var cached = this._cache._space[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && /^[ \t\n\r]/.test(chunk0)) {
        address0 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address0 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::space', '[ \\t\\n\\r]']);
        }
      }
      this._cache._space[index0] = [address0, this._offset];
      return address0;
    },

    _read_function () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._function = this._cache._function || {};
      var cached = this._cache._function[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(4);
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 8;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 !== null && chunk0.toLowerCase() === 'function'.toLowerCase()) {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 8), this._offset, []);
        this._offset = this._offset + 8;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::function', '`function`']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 1) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          address4 = this._read_type();
          if (address4 !== FAILURE) {
            elements0[0] = address4;
            var address5 = FAILURE;
            var index3 = this._offset, elements2 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements2.push(address6);
              } else {
                break;
              }
            }
            if (elements2.length >= 0) {
              address5 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              address7 = this._read_identifier();
              if (address7 !== FAILURE) {
                elements0[1] = address7;
                var address8 = FAILURE;
                address8 = this._read_lparen();
                if (address8 !== FAILURE) {
                  var address9 = FAILURE;
                  var index4 = this._offset, elements3 = [], address10 = null;
                  while (true) {
                    address10 = this._read_space();
                    if (address10 !== FAILURE) {
                      elements3.push(address10);
                    } else {
                      break;
                    }
                  }
                  if (elements3.length >= 0) {
                    address9 = new TreeNode(this._input.substring(index4, this._offset), index4, elements3);
                    this._offset = this._offset;
                  } else {
                    address9 = FAILURE;
                  }
                  if (address9 !== FAILURE) {
                    var address11 = FAILURE;
                    address11 = this._read_parameter_list();
                    if (address11 !== FAILURE) {
                      elements0[2] = address11;
                      var address12 = FAILURE;
                      var index5 = this._offset, elements4 = [], address13 = null;
                      while (true) {
                        address13 = this._read_space();
                        if (address13 !== FAILURE) {
                          elements4.push(address13);
                        } else {
                          break;
                        }
                      }
                      if (elements4.length >= 0) {
                        address12 = new TreeNode(this._input.substring(index5, this._offset), index5, elements4);
                        this._offset = this._offset;
                      } else {
                        address12 = FAILURE;
                      }
                      if (address12 !== FAILURE) {
                        var address14 = FAILURE;
                        address14 = this._read_rparen();
                        if (address14 !== FAILURE) {
                          var address15 = FAILURE;
                          var index6 = this._offset, elements5 = [], address16 = null;
                          while (true) {
                            address16 = this._read_space();
                            if (address16 !== FAILURE) {
                              elements5.push(address16);
                            } else {
                              break;
                            }
                          }
                          if (elements5.length >= 0) {
                            address15 = new TreeNode(this._input.substring(index6, this._offset), index6, elements5);
                            this._offset = this._offset;
                          } else {
                            address15 = FAILURE;
                          }
                          if (address15 !== FAILURE) {
                            var address17 = FAILURE;
                            var index7 = this._offset;
                            address17 = this._read_expression();
                            if (address17 === FAILURE) {
                              this._offset = index7;
                              address17 = this._read_block();
                              if (address17 === FAILURE) {
                                this._offset = index7;
                              }
                            }
                            if (address17 !== FAILURE) {
                              elements0[3] = address17;
                            } else {
                              elements0 = null;
                              this._offset = index1;
                            }
                          } else {
                            elements0 = null;
                            this._offset = index1;
                          }
                        } else {
                          elements0 = null;
                          this._offset = index1;
                        }
                      } else {
                        elements0 = null;
                        this._offset = index1;
                      }
                    } else {
                      elements0 = null;
                      this._offset = index1;
                    }
                  } else {
                    elements0 = null;
                    this._offset = index1;
                  }
                } else {
                  elements0 = null;
                  this._offset = index1;
                }
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeFunctionDefinition(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._function[index0] = [address0, this._offset];
      return address0;
    },

    _read_parameter_list () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._parameter_list = this._cache._parameter_list || {};
      var cached = this._cache._parameter_list[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var index2 = this._offset, elements0 = new Array(3);
      var address1 = FAILURE;
      address1 = this._read_type();
      if (address1 !== FAILURE) {
        elements0[0] = address1;
        var address2 = FAILURE;
        var index3 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 1) {
          address2 = new TreeNode(this._input.substring(index3, this._offset), index3, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          address4 = this._read_identifier();
          if (address4 !== FAILURE) {
            elements0[1] = address4;
            var address5 = FAILURE;
            var index4 = this._offset, elements2 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements2.push(address6);
              } else {
                break;
              }
            }
            if (elements2.length >= 0) {
              address5 = new TreeNode(this._input.substring(index4, this._offset), index4, elements2);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              var index5 = this._offset, elements3 = [], address8 = null;
              while (true) {
                var index6 = this._offset, elements4 = new Array(2);
                var address9 = FAILURE;
                var chunk0 = null, max0 = this._offset + 1;
                if (max0 <= this._inputSize) {
                  chunk0 = this._input.substring(this._offset, max0);
                }
                if (chunk0 === ',') {
                  address9 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                  this._offset = this._offset + 1;
                } else {
                  address9 = FAILURE;
                  if (this._offset > this._failure) {
                    this._failure = this._offset;
                    this._expected = [];
                  }
                  if (this._offset === this._failure) {
                    this._expected.push(['Medcodelogic::parameter_list', '","']);
                  }
                }
                if (address9 !== FAILURE) {
                  var address10 = FAILURE;
                  var index7 = this._offset, elements5 = [], address11 = null;
                  while (true) {
                    address11 = this._read_space();
                    if (address11 !== FAILURE) {
                      elements5.push(address11);
                    } else {
                      break;
                    }
                  }
                  if (elements5.length >= 0) {
                    address10 = new TreeNode(this._input.substring(index7, this._offset), index7, elements5);
                    this._offset = this._offset;
                  } else {
                    address10 = FAILURE;
                  }
                  if (address10 !== FAILURE) {
                    var address12 = FAILURE;
                    address12 = this._read_type();
                    if (address12 !== FAILURE) {
                      elements4[0] = address12;
                      var address13 = FAILURE;
                      var index8 = this._offset, elements6 = [], address14 = null;
                      while (true) {
                        address14 = this._read_space();
                        if (address14 !== FAILURE) {
                          elements6.push(address14);
                        } else {
                          break;
                        }
                      }
                      if (elements6.length >= 1) {
                        address13 = new TreeNode(this._input.substring(index8, this._offset), index8, elements6);
                        this._offset = this._offset;
                      } else {
                        address13 = FAILURE;
                      }
                      if (address13 !== FAILURE) {
                        var address15 = FAILURE;
                        address15 = this._read_identifier();
                        if (address15 !== FAILURE) {
                          elements4[1] = address15;
                          var address16 = FAILURE;
                          var index9 = this._offset, elements7 = [], address17 = null;
                          while (true) {
                            address17 = this._read_space();
                            if (address17 !== FAILURE) {
                              elements7.push(address17);
                            } else {
                              break;
                            }
                          }
                          if (elements7.length >= 0) {
                            address16 = new TreeNode(this._input.substring(index9, this._offset), index9, elements7);
                            this._offset = this._offset;
                          } else {
                            address16 = FAILURE;
                          }
                          if (address16 !== FAILURE) {
                          } else {
                            elements4 = null;
                            this._offset = index6;
                          }
                        } else {
                          elements4 = null;
                          this._offset = index6;
                        }
                      } else {
                        elements4 = null;
                        this._offset = index6;
                      }
                    } else {
                      elements4 = null;
                      this._offset = index6;
                    }
                  } else {
                    elements4 = null;
                    this._offset = index6;
                  }
                } else {
                  elements4 = null;
                  this._offset = index6;
                }
                if (elements4 === null) {
                  address8 = FAILURE;
                } else {
                  address8 = new TreeNode28(this._input.substring(index6, this._offset), index6, elements4);
                  this._offset = this._offset;
                }
                if (address8 !== FAILURE) {
                  elements3.push(address8);
                } else {
                  break;
                }
              }
              if (elements3.length >= 0) {
                address7 = new TreeNode(this._input.substring(index5, this._offset), index5, elements3);
                this._offset = this._offset;
              } else {
                address7 = FAILURE;
              }
              if (address7 !== FAILURE) {
                elements0[2] = address7;
              } else {
                elements0 = null;
                this._offset = index2;
              }
            } else {
              elements0 = null;
              this._offset = index2;
            }
          } else {
            elements0 = null;
            this._offset = index2;
          }
        } else {
          elements0 = null;
          this._offset = index2;
        }
      } else {
        elements0 = null;
        this._offset = index2;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeParameterList(this._input, index2, this._offset, elements0);
        this._offset = this._offset;
      }
      if (address0 === FAILURE) {
        address0 = new TreeNode(this._input.substring(index1, index1), index1, []);
        this._offset = index1;
      }
      this._cache._parameter_list[index0] = [address0, this._offset];
      return address0;
    },

    _read_block () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._block = this._cache._block || {};
      var cached = this._cache._block[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset, elements0 = new Array(1);
      var address1 = FAILURE;
      var chunk0 = null, max0 = this._offset + 1;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === '{') {
        address1 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
        this._offset = this._offset + 1;
      } else {
        address1 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::block', '"{"']);
        }
      }
      if (address1 !== FAILURE) {
        var address2 = FAILURE;
        var index2 = this._offset, elements1 = [], address3 = null;
        while (true) {
          address3 = this._read_space();
          if (address3 !== FAILURE) {
            elements1.push(address3);
          } else {
            break;
          }
        }
        if (elements1.length >= 0) {
          address2 = new TreeNode(this._input.substring(index2, this._offset), index2, elements1);
          this._offset = this._offset;
        } else {
          address2 = FAILURE;
        }
        if (address2 !== FAILURE) {
          var address4 = FAILURE;
          address4 = this._read_expression();
          if (address4 !== FAILURE) {
            elements0[0] = address4;
            var address5 = FAILURE;
            var index3 = this._offset, elements2 = [], address6 = null;
            while (true) {
              address6 = this._read_space();
              if (address6 !== FAILURE) {
                elements2.push(address6);
              } else {
                break;
              }
            }
            if (elements2.length >= 0) {
              address5 = new TreeNode(this._input.substring(index3, this._offset), index3, elements2);
              this._offset = this._offset;
            } else {
              address5 = FAILURE;
            }
            if (address5 !== FAILURE) {
              var address7 = FAILURE;
              var chunk1 = null, max1 = this._offset + 1;
              if (max1 <= this._inputSize) {
                chunk1 = this._input.substring(this._offset, max1);
              }
              if (chunk1 === '}') {
                address7 = new TreeNode(this._input.substring(this._offset, this._offset + 1), this._offset, []);
                this._offset = this._offset + 1;
              } else {
                address7 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::block', '"}"']);
                }
              }
              if (address7 !== FAILURE) {
              } else {
                elements0 = null;
                this._offset = index1;
              }
            } else {
              elements0 = null;
              this._offset = index1;
            }
          } else {
            elements0 = null;
            this._offset = index1;
          }
        } else {
          elements0 = null;
          this._offset = index1;
        }
      } else {
        elements0 = null;
        this._offset = index1;
      }
      if (elements0 === null) {
        address0 = FAILURE;
      } else {
        address0 = this._actions.makeBlock(this._input, index1, this._offset, elements0);
        this._offset = this._offset;
      }
      this._cache._block[index0] = [address0, this._offset];
      return address0;
    },

    _read_type () {
      var address0 = FAILURE, index0 = this._offset;
      this._cache._type = this._cache._type || {};
      var cached = this._cache._type[index0];
      if (cached) {
        this._offset = cached[1];
        return cached[0];
      }
      var index1 = this._offset;
      var chunk0 = null, max0 = this._offset + 4;
      if (max0 <= this._inputSize) {
        chunk0 = this._input.substring(this._offset, max0);
      }
      if (chunk0 === 'list') {
        address0 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
        this._offset = this._offset + 4;
      } else {
        address0 = FAILURE;
        if (this._offset > this._failure) {
          this._failure = this._offset;
          this._expected = [];
        }
        if (this._offset === this._failure) {
          this._expected.push(['Medcodelogic::type', '"list"']);
        }
      }
      if (address0 === FAILURE) {
        this._offset = index1;
        var chunk1 = null, max1 = this._offset + 4;
        if (max1 <= this._inputSize) {
          chunk1 = this._input.substring(this._offset, max1);
        }
        if (chunk1 === 'date') {
          address0 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
          this._offset = this._offset + 4;
        } else {
          address0 = FAILURE;
          if (this._offset > this._failure) {
            this._failure = this._offset;
            this._expected = [];
          }
          if (this._offset === this._failure) {
            this._expected.push(['Medcodelogic::type', '"date"']);
          }
        }
        if (address0 === FAILURE) {
          this._offset = index1;
          var chunk2 = null, max2 = this._offset + 6;
          if (max2 <= this._inputSize) {
            chunk2 = this._input.substring(this._offset, max2);
          }
          if (chunk2 === 'number') {
            address0 = new TreeNode(this._input.substring(this._offset, this._offset + 6), this._offset, []);
            this._offset = this._offset + 6;
          } else {
            address0 = FAILURE;
            if (this._offset > this._failure) {
              this._failure = this._offset;
              this._expected = [];
            }
            if (this._offset === this._failure) {
              this._expected.push(['Medcodelogic::type', '"number"']);
            }
          }
          if (address0 === FAILURE) {
            this._offset = index1;
            var chunk3 = null, max3 = this._offset + 6;
            if (max3 <= this._inputSize) {
              chunk3 = this._input.substring(this._offset, max3);
            }
            if (chunk3 === 'string') {
              address0 = new TreeNode(this._input.substring(this._offset, this._offset + 6), this._offset, []);
              this._offset = this._offset + 6;
            } else {
              address0 = FAILURE;
              if (this._offset > this._failure) {
                this._failure = this._offset;
                this._expected = [];
              }
              if (this._offset === this._failure) {
                this._expected.push(['Medcodelogic::type', '"string"']);
              }
            }
            if (address0 === FAILURE) {
              this._offset = index1;
              var chunk4 = null, max4 = this._offset + 4;
              if (max4 <= this._inputSize) {
                chunk4 = this._input.substring(this._offset, max4);
              }
              if (chunk4 === 'void') {
                address0 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
                this._offset = this._offset + 4;
              } else {
                address0 = FAILURE;
                if (this._offset > this._failure) {
                  this._failure = this._offset;
                  this._expected = [];
                }
                if (this._offset === this._failure) {
                  this._expected.push(['Medcodelogic::type', '"void"']);
                }
              }
              if (address0 === FAILURE) {
                this._offset = index1;
                var chunk5 = null, max5 = this._offset + 4;
                if (max5 <= this._inputSize) {
                  chunk5 = this._input.substring(this._offset, max5);
                }
                if (chunk5 === 'code') {
                  address0 = new TreeNode(this._input.substring(this._offset, this._offset + 4), this._offset, []);
                  this._offset = this._offset + 4;
                } else {
                  address0 = FAILURE;
                  if (this._offset > this._failure) {
                    this._failure = this._offset;
                    this._expected = [];
                  }
                  if (this._offset === this._failure) {
                    this._expected.push(['Medcodelogic::type', '"code"']);
                  }
                }
                if (address0 === FAILURE) {
                  this._offset = index1;
                  var chunk6 = null, max6 = this._offset + 7;
                  if (max6 <= this._inputSize) {
                    chunk6 = this._input.substring(this._offset, max6);
                  }
                  if (chunk6 === 'boolean') {
                    address0 = this._actions.makeType(this._input, this._offset, this._offset + 7, []);
                    this._offset = this._offset + 7;
                  } else {
                    address0 = FAILURE;
                    if (this._offset > this._failure) {
                      this._failure = this._offset;
                      this._expected = [];
                    }
                    if (this._offset === this._failure) {
                      this._expected.push(['Medcodelogic::type', '"boolean"']);
                    }
                  }
                  if (address0 === FAILURE) {
                    this._offset = index1;
                  }
                }
              }
            }
          }
        }
      }
      this._cache._type[index0] = [address0, this._offset];
      return address0;
    }
  };

  var Parser = function(input, actions, types) {
    this._input = input;
    this._inputSize = input.length;
    this._actions = actions;
    this._types = types;
    this._offset = 0;
    this._cache = {};
    this._failure = 0;
    this._expected = [];
  };

  Parser.prototype.parse = function() {
    var tree = this._read_expression();
    if (tree !== FAILURE && this._offset === this._inputSize) {
      return tree;
    }
    if (this._expected.length === 0) {
      this._failure = this._offset;
      this._expected.push(['Medcodelogic', '<EOF>']);
    }
    this.constructor.lastError = { offset: this._offset, expected: this._expected };
    throw new SyntaxError(formatError(this._input, this._failure, this._expected));
  };

  Object.assign(Parser.prototype, Grammar);


  function parse(input, options) {
    options = options || {};
    var parser = new Parser(input, options.actions, options.types);
    return parser.parse();
  }

  function formatError(input, offset, expected) {
    var lines = input.split(/\n/g),
        lineNo = 0,
        position = 0;

    while (position <= offset) {
      position += lines[lineNo].length + 1;
      lineNo += 1;
    }

    var line = lines[lineNo - 1],
        message = 'Line ' + lineNo + ': expected one of:\n\n';

    for (var i = 0; i < expected.length; i++) {
      message += '    - ' + expected[i][1] + '\n';
    }
    var number = lineNo.toString();
    while (number.length < 6) number = ' ' + number;
    message += '\n' + number + ' | ' + line + '\n';

    position -= line.length + 10;

    while (position < offset) {
      message += ' ';
      position += 1;
    }
    return message + '^';
  }

  function inherit(subclass, parent) {
    function chain () {};
    chain.prototype = parent.prototype;
    subclass.prototype = new chain();
    subclass.prototype.constructor = subclass;
  }


  var exported = { Grammar: Grammar, Parser: Parser, parse: parse };

  if (typeof require === 'function' && typeof exports === 'object') {
    Object.assign(exports, exported);
  } else {
    var ns = (typeof this === 'undefined') ? window : this;
    ns.Medcodelogic = exported;
  }
})();
export default Medcodelogic;
